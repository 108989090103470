import useAuth from '../hooks/useAuth';
import Navbar from '../components/Navbar';
import Breadcrumbs from '../components/Breadcrumbs';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Offers = () => {

	const {t} = useTranslation();

	const { user, isLoading } = useAuth();

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	return ( 
		<>
			<Navbar />
			<main>
			<div className="container">
				<Breadcrumbs currentPage={t('Ofertas')} />
				<div className="row">
					<div className="p-0 col-12 title">
						<h3 className="p-1">{t('Crear una nueva oferta')}</h3>
					</div>
				</div>
				<div className="row">
					<div className="card">
						<div className="card-body">
							<p>{t('Si quieres aumentar las ventas de tu medio, puedes enviar un email a dariana@prensalink.com con el nuevo precio de oferta. La oferta debe ser mínimo un 25% por debajo respecto al precio original.')}.</p>
							<p>{t('Los medios en oferta aumentan sus ventas hasta un 300%')}:</p>
							<ul>
								<li>{t('Aparecen marcados como Rebajados')}</li>
								<li>{t('Se destacan al principio de la tabla')}</li>
								<li>{t('Se envía la oferta en nuestra mailing semanal con una base de datos de más de 20.000 usuarios registrados')}</li>
							</ul>
							<p>{t('Ten en cuenta que podrán llegarte artículos patrocinados a precio de esta promoción unos días después de la fecha indicada por demora en la redacción y aprobación del texto por parte del cliente. Puedes solicitar un reporte de todos los artículos vendidos el día en el que finaliza la promoción.')}</p>
						</div>
					</div>
				</div>
				</div>
			</main>
		</>
	 );
}
 
export default Offers;